import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import "./Reusable.css";
import "./ReusableAnalysis.css";
import "./CompleteAccount.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import ErrorPage from "./Pages/Error/ErrorPage";
import SignInUp from "./Pages/SignIn/SignInUp";
import LoadingVisual from "./Pages/App/LoadingVisual";
import {  RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
const AppLoading = lazy(() => import("./Pages/App/AppLoading"));
const SetImage = lazy(() => import("./Pages/CompleteAccount/SetImage/SetImage"));
const SetUserDetails = lazy(() => import("./Pages/CompleteAccount/SetUserDetails/SetUserDetails"));
const PaymentMade = lazy(() => import("./Pages/App/PaymentMade"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignInUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/app",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <AppLoading />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/set-user-details",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <SetUserDetails />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-details-profile-image",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <SetImage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/paymentComplete",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <PaymentMade />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <Suspense fallback={<LoadingVisual/>}>
        <RouterProvider router={router} />
      </Suspense>
    </RouterProvider>
  </React.StrictMode>
);
reportWebVitals();
serviceWorker.unregister();

