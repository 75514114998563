import React from 'react'
import SideBar from './Components/SideBar/SideBar'
import LoadingDetails from './LoadingDetails'

const LoadingVisual = () => {
  return (
    <div className='page'>
      <div className="siteHub__app--home">
        <SideBar open={false} setOpen={()=>{}} appDisplay={''} setAppDisplay={()=>{}} projectToView={()=>{}} setOpenSignIn={()=>{}} user={''}projectDisplay={''} setProjectDisplay={()=>{}} isHnsDash={''} setIsHnsDash={()=>{}}joinRequests={[]}  setViewProject={()=>{}} setIsSplitMode={()=>{}} setProjectToQuote={()=>{}}/>
        <LoadingDetails />
      </div>
    </div>
  )
}

export default LoadingVisual