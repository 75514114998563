import React from 'react'

const LoadingDetails = () => {
  return (
    <div className="app__home--display split__home">
    <div className="sss">
    <div className='dashboard page__padding'>
    <div className="dashboard__left">
      <div className="dashboard__left__top">
        <div className='dummy__container'>
          <h4></h4>
        </div>
        <div className='dummy__container'>
          <h4></h4>
        </div>
        <div className='dummy__container'>
          <h4></h4>
        </div>
        <div className='dummy__container'>
          <h4></h4>
        </div>
      </div>
      <div className='projects__analysis--data use__dash__container'>
        <div className="dummy__container">
          <h5></h5>
        </div>
      </div>
    </div>

    <div className="dashboard__right--content "> 
    <div className='dummy__container' style={{minHeight: '20rem'}}>
    </div>
    <div className='dashboard__right__bottom'>
    <div className='use__dash__container dummy__container dashboard__hns--information'></div>
    <div className='use__dash__container dummy__container dashboard__hns--information'></div>
    <div className='use__dash__container dummy__container dashboard__hns--information'></div>
   </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default LoadingDetails