import React from 'react'

const SideDashLink = ({ appDisplay,setAppDisplay,location,fa }) => {
  return (
    <span className={`sidebar__link ${appDisplay === location ? 'sidebar__link--active' : ''}`} onClick={() => setAppDisplay(location)}>
      {fa} {location}
    </span>
  )
}

export default SideDashLink