import React, { useEffect } from 'react'
import SignIn from './SignInComponents/SignIn'
import SignUp from './SignInComponents/SignUp'
import './SignInUp.css'
import { useState } from 'react'
import {  useLocation, useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { collection, doc, getDoc, setDoc,  } from 'firebase/firestore'
import { auth, db } from '../App/Functions/firebase-config'
import ErrorAlert from '../App/Components/ErrorAlert/ErrorAlert'
import Loading from '../App/Components/Loading/Loading'
import ModalBg from '../App/Components/ModalBg/ModalBg'
import ResetPassword from './SignInComponents/ResetPassword/ResetPassword'
import Success from '../App/Components/ReUse/Success'

const SignInUp = () => {
  const location = useLocation();
  const [isSignIn, setIsSignIn] = useState(true);
  const [foundEmail,setFoundEmail]=useState('')
  const [userFullName, setUserFullName] = useState('')
  const [resetPassword, setResetPassword] = useState(false)
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()
  const [user, userLoading] = useAuthState(auth);
  const [loading, setLoading] = useState(false)
  const [message,setMessage]=useState('')
  const [referralCus,setReferralCus]=useState('')
  
  useEffect(() => {
    const currentURL = window.location.href;
    const urlSearchParams = new URLSearchParams(currentURL);
    const user = urlSearchParams.get('user');
    const email = urlSearchParams.get('email');
    const referralId = urlSearchParams.get('referralLink');
    if(email){
      setFoundEmail(email)
    }
    if(user){
      setUserFullName(user)
    }
    if(referralId){
      setReferralCus(referralId)
    }
    
  }, [location.search]);


  useEffect(()=>{
    if(user){
      setLoading('Locating your account')
      signInUser()
    }
  },[auth, user, userLoading])


  const signInUser = async () => {
    const uid = user.uid;
    if(user.uid && referralCus){
      const response = await fetch(
        "https://us-central1-sitehub-98a8a.cloudfunctions.net/cusTriggeredFindRel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CUS__REL__ID: referralCus,
            CUS__ID: user.uid,
          }),
        }
      );
      if (response.ok) {
        console.log(response.ok);
      } else {
        console.log(response.status);
      }
    }
    const usersCollectionRef = collection(db, 'customers');
    const userDocRef = doc(usersCollectionRef, uid);
    const userDocSnapshot = await getDoc(userDocRef);
    if(userDocSnapshot.exists()){ 
      const users = userDocSnapshot.data()
      sessionStorage.setItem('user', JSON.stringify(users));
      navigate('/app', { state: { account: users } });
    } else {
      if(user){
        const newUser = { email: user.email, fullName: user.displayName ? user.displayName : '', id: user.uid }
        const docRef = doc(db, 'customers', user.uid)
        await setDoc(docRef, newUser);
        sessionStorage.setItem('user', JSON.stringify(newUser));
        navigate('/set-user-details', { state: { account: newUser } });
      }
    }
  }

  return (
    <>

      {message && (
        <ErrorAlert message={message} setMessage={setMessage}/>
      )}

      {success && (
        <Success success={success} setSuccess={setSuccess} />
      )}
      {loading && (
        <div className='redirecting-page gradient__bg'>
          <Loading message={loading}/>
        </div>
      )} 

      {resetPassword && (
        <ModalBg open={resetPassword} closePopUp={()=>{setResetPassword(false)}}>
          <ResetPassword setSuccess={setSuccess} setResetPassword={setResetPassword}/>
        </ModalBg>
      )}

      <div className='new__company'>
        <div className="new__company__bg"></div>
        <div className="details__container">
          <div className='flex__column gap1'>
            <div className="flex__column">
              <h2>siteHUB customer portal</h2>
              <p>{isSignIn ? 'Please sign in to continue' : 'Enter your details to sign up'}</p>
            </div>
              {isSignIn ?
                <SignIn setIsSignIn={setIsSignIn}setMessage={setMessage} setLoading={setLoading}/>
                :
                <SignUp dbase={'customers'} setIsSignIn={setIsSignIn} setLoading={setLoading} foundEmail={foundEmail} userFullName={userFullName} />
              }
            <div className="flex__end flex__column">
              {!isSignIn && (
                <p>By signing up you agree to our
                  <a style={{textDecoration: 'underline', marginLeft: '0.5rem'}} className='span__hover' href="/privacy" target="_blank" rel="noopener noreferrer">
                    <span>terms and conditions</span>
                  </a>
                </p>
              )}
              <p>{isSignIn ? 'Set up your account' : 'Already have an account?'} <span style={{textDecoration: 'underline'}} className='span__hover' onClick={()=>setIsSignIn(!isSignIn)}>Sign {!isSignIn ? 'in' : 'up'}</span></p>
              <p>Forgotten password? <span style={{textDecoration: 'underline'}} className='span__hover' onClick={()=>setResetPassword(true)}>Reset</span></p>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SignInUp

