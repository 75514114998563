
import { FaCaretSquareLeft,  FaCaretSquareRight,  FaEnvelope, FaHome, FaInfoCircle,  FaUserCircle } from 'react-icons/fa'
import './SideBar.css'
import SideDashLink from './SideBarComponents/SideDashLink'
const SideBar = ({ open,setOpen, appDisplay, setAppDisplay }) => {


  return (
        <div className='siteHub__app--sidebar' style={{ minWidth: open ? '20rem' : '0rem', maxWidth: open ? '20rem' : '3rem', width: open ? '20rem' : '3rem'  }}>
          {open ? (
            <>
              <div className="side__header">
                <h4>siteHUB</h4>
                <div className='span__hover' onClick={()=>{setOpen(false)}}><FaCaretSquareLeft/></div>
              </div>

              <div className="side__bar__overflow">
                <div className="sidebar__label">
                  <p>DASHBOARD</p>
                </div>

                <div className="sidebar__links">
                  <div className={`sidebar__link ${appDisplay === 'home' ? 'sidebar__link--active' : ''} `} onClick={() => setAppDisplay('home')}>
                    <FaHome/> Home
                  </div>
                  <div className={`sidebar__link ${appDisplay === 'RFI' ? 'sidebar__link--active' : ''} `} onClick={() => setAppDisplay('RFI')}>
                    <FaInfoCircle /> RFI
                  </div>
                </div>

                <div className="sidebar__label">
                  <p>ACCOUNT</p>
                </div>
                <div className="sidebar__links">
                  <SideDashLink appDisplay={appDisplay} setAppDisplay={setAppDisplay} location={'account'} fa={<FaUserCircle/>}/>
                  <SideDashLink appDisplay={appDisplay} setAppDisplay={setAppDisplay} location={'messages'} fa={<FaEnvelope />}/>
                </div>
              </div>
            </>
          )
          :
              <>
                <div className="open__closed" onClick={()=>{setOpen(true)}}>
                  <FaCaretSquareRight />
                </div>
                <div className="sitehub__closed__links">
                  <div onClick={()=>{setOpen(true)}} className='span__hover'>
                    <FaCaretSquareRight />
                  </div>

                  <div className={`span__hover ${appDisplay === 'home' ? 'active__close' : ''}`} onClick={()=>{setAppDisplay('home')}}>
                    <FaHome/>
                  </div>
    
                  <div className={`span__hover ${appDisplay === 'RFI' ? 'active__close' : ''}`} onClick={()=>
                    setAppDisplay('RFI')}>
                    <FaInfoCircle/>
                  </div>

                  <div className={`span__hover ${appDisplay === 'account' ? 'active__close' : ''}`} onClick={()=>{setAppDisplay('account')}}>
                    <FaUserCircle/>
                  </div>

                  <div className={`span__hover ${appDisplay === 'messages' ? 'active__close' : ''}`} onClick={()=>{setAppDisplay('messages')}}>
                    <FaEnvelope/>
                  </div>
                </div>
              </>

          }
        </div>
  )
}

export default SideBar